import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useState } from "react"
import Layout from "../../components/_App/layout"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import DownloadAndCopyButtons from "../../helpers/buttons"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import CopiedMessage from "../../helpers/CopiedMessage"

const parent = [{
  name: "Tools",
  link: 'tools',
}];

function RepeatText() {
  const [inputText, setInputText] = useState('');
  const [repeatCount, setRepeatCount] = useState(1);
  const [outputText, setOutputText] = useState('');
  const [message, setMessage] = useState("");

  const  handleGenerate  =  ( )  =>  {
    const repeatedText = (inputText + '\n').repeat(repeatCount);
    setOutputText(repeatedText);
  };

  const showMessage = (msg) => {
    setMessage(msg);
  };

  return (
    <Layout>
      <Seo title="Repeat Text Generator Tool"/>
      <Navbar/>
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>Repeat Text Generator</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="Repeat Text Generator" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <textarea name="" value={inputText}  onChange={(e) => setInputText(e.target.value)} className={convertStyled.text_field} id="" cols="30" rows="10"/>
                <div className='d-flex gap-4 mt-2 align-items-center'>
                  <div className='d-flex gap-3'>
                    <label>Repeat Count: </label>
                    <input
                      type="number"
                      value={repeatCount}
                      onChange={(e) => setRepeatCount(parseInt(e.target.value))}
                    />
                  </div>
                  <button className='btn btn-primary px-3 py-2' onClick={handleGenerate}>Generate</button>
                </div>
              </div>

              <div className="col-lg-6">
                <textarea value={outputText} className={`${convertStyled.text_field}`} cols="30" rows="10"/>
                <DownloadAndCopyButtons previewValue={outputText}  disabled={!outputText} showMessage={showMessage}/>
                {message && (<CopiedMessage message={message} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default RepeatText